.br--b-dark {
  border: 4px solid $dark;
  border-radius: 10px;
}

ul {
  list-style-type: none;
  li {
    cursor: pointer;
  }
}

.fs--clash-display {
  font-family: "Clash Display", sans-serif;
}

.top-nav {
  li:hover {
    color: $info;
  }
}

.nav-border {
  border: 1px solid #F3F3F3;
}
.z-10 {
  z-index: 10;
}
.nav-avatar img{
  width: 40px !important;
}
.nav-drop-avatar {
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;

  img {
    width: 80px;
  }
}