
.cursor--pointer {
  cursor: pointer;
}

.box-shadow {
  box-shadow: inset 0 -1px 0 rgba($black, 0.12), inset 0 1px 0 rgba($black, 0.12);
  &--bottom {
    box-shadow: inset 0 -1px 0 rgba($black, 0.12);
  }
  &--top {
    box-shadow: 0 -1px 0 rgba($black, 0.12);
  }
}

.text--black-60 {
  color: rgba($black, 0.6);
}

.text--white-60 {
  color: rgba($white, 0.6);
}

.main-content {
  min-height: calc(100vh - 332px);
  @include media-breakpoint-up(lg) {
   min-height: calc(100vh - 188px);
  }
}

.overflow-y-scroll {
  @include media-breakpoint-up(lg) {
    overflow-y: scroll;
  }
}

.bg {
  &--red-15 {
    background-color: rgba(193, 10, 10, 0.15);
  }
  &--success-15 {
    background-color: rgba(35, 193, 10, 0.15);
  }
  &--blue {
    background-color: #4690FC;
  }
  &--blue-15 {
    background-color: #C4DCFE;
  }
}

.btn {
  padding: 12px 24px !important;
  border-radius: 4px !important;
  font-size: 16px !important;
  transition: 0.2s ease-in !important;
  &-primary {
    @extend .btn;
    background-color: $primary;
    color: $white;
    &:hover {
      background-color: #3640DD;
    }
  }
  &-blue {
    @extend .btn;
    background-color: #1D4ED8;
    color: $white;
    &:hover {
      opacity: 0.99 !important;
    }
  }
  &-white {
    @extend .btn;
    background-color: $white;
    color: #4690FC;
    border: none;
    &:hover {
      opacity: 0.99 !important;
    }
  }
}

.br {
  &--b-light {
    border-bottom: 1px solid #D9D9D9;
  }
  &--blue-15 {
    border-color: #C4DCFE !important;
  }
}

.radius {
  &--top-right {
    border-top-right-radius: 5px;
  }
  &--top-left {
    border-top-left-radius: 5px;
  }
  &--bottom-right {
    border-bottom-right-radius: 5px;
  }
  &--bottom-left {
    border-bottom-left-radius: 5px;
  }
}
